import "./index.css";
import "./static/gift-aid-logo.png";

class GiftaidBlock {
  constructor(node) {
    this.node = node;

    this.ownMoney = node.querySelector(".js-donating_own_money");
    this.inMemory = node.querySelector(".js-donating_in_memory");
    this.fromEvent = node.querySelector(".js-donating_from_event");
    this.fromOrg = node.querySelector(".js-donating_for_organisation");
    this.fromLegacy = node.querySelector(".js-donating_for_legacy");
    this.inMemoryOwn = node.querySelector(".js-donating_in_memory_own_money");
    this.inMemoryCollection = node.querySelector(
      ".js-donating_in_memory_collection"
    );

    this.giftaidOption = node.querySelector(".js-Giftaid-option");
    this.gitaidCheckbox = node.querySelector(".js-Giftaid-option input");
    this.inMemoryInputs = node.querySelector(".js-Giftaid-in-memory");
    this.inMemoryInputFields = node.querySelectorAll(
      ".js-Giftaid-in-memory input"
    );
    this.fromEventInputs = node.querySelector(".js-Giftaid-event");
    this.fromEventInputFields = node.querySelector(
      ".js-Giftaid-event textarea"
    );
    this.fromEventInputSelect = node.querySelector(".js-Giftaid-event select");
    this.fromOrgInputs = node.querySelector(".js-Giftaid-organisation");
    this.fromOrgInputFields = node.querySelectorAll(
      ".js-Giftaid-organisation input"
    );
    this.fromLegacyInputs = node.querySelector(".js-Giftaid-legacy");
    this.fromLegacyInputFields = node.querySelectorAll(
      ".js-Giftaid-legacy input"
    );

    this.init = this.init.bind(this);

    this.giftaidSelected = this.giftaidSelected.bind(this);
    this.inMemorySelected = this.inMemorySelected.bind(this);
    this.inMemoryOwnSelected = this.inMemoryOwnSelected.bind(this);
    this.inMemoryCollectionSelected =
      this.inMemoryCollectionSelected.bind(this);
    this.fromEventSelected = this.fromEventSelected.bind(this);
    this.fromOrgSelected = this.fromOrgSelected.bind(this);
    this.fromLegacySelected = this.fromLegacySelected.bind(this);

    this.hideGiftaid = this.hideGiftaid.bind(this);
    this.showGiftaid = this.showGiftaid.bind(this);
    this.uncheckGiftaid = this.uncheckGiftaid.bind(this);

    this.hideInMemoryInputs = this.hideInMemoryInputs.bind(this);
    this.showInMemoryInputs = this.showInMemoryInputs.bind(this);
    this.clearInMemoryInputs = this.clearInMemoryInputs.bind(this);

    this.hideFromEventInputs = this.hideFromEventInputs.bind(this);
    this.showFromEventInputs = this.showFromEventInputs.bind(this);
    this.clearFromEventInputs = this.clearFromEventInputs.bind(this);

    this.hideFromOrgInputs = this.hideFromOrgInputs.bind(this);
    this.showFromOrgInputs = this.showFromOrgInputs.bind(this);
    this.clearFromOrgInputs = this.clearFromOrgInputs.bind(this);

    this.hideFromLegacyInputs = this.hideFromLegacyInputs.bind(this);
    this.showFromLegacyInputs = this.showFromLegacyInputs.bind(this);
    this.clearFromLegacyInputs = this.clearFromLegacyInputs.bind(this);

    this.init(
      this.ownMoney,
      this.inMemory,
      this.inMemoryOwn,
      this.inMemoryCollection,
      this.fromEvent,
      this.fromOrg,
      this.fromLegacy
    );
    this.ownMoney.addEventListener("change", this.giftaidSelected);
    this.inMemory.addEventListener("change", this.inMemorySelected);
    if (this.inMemoryOwn) {
      this.inMemoryOwn.addEventListener("change", this.inMemoryOwnSelected);
    }
    if (this.inMemoryCollection) {
      this.inMemoryCollection.addEventListener(
        "change",
        this.inMemoryCollectionSelected
      );
    }
    if (this.fromEvent) {
      this.fromEvent.addEventListener("change", this.fromEventSelected);
    }
    if (this.fromOrg) {
      this.fromOrg.addEventListener("change", this.fromOrgSelected);
    }
    if (this.fromLegacy) {
      this.fromLegacy.addEventListener("change", this.fromLegacySelected);
    }
  }

  init(
    ownMoney,
    inMemory,
    inMemoryOwn,
    inMemoryCollection,
    fromEvent,
    fromOrg,
    fromLegacy
  ) {
    if (ownMoney.checked === true) {
      this.giftaidSelected();
    } else if (inMemory.checked === true) {
      this.inMemorySelected();
    } else if (fromEvent && fromEvent.checked === true) {
      this.fromEventSelected();
    } else if (fromOrg && fromOrg.checked === true) {
      this.fromOrgSelected();
    } else if (fromLegacy && fromLegacy.checked === true) {
      this.fromLegacySelected();
    }
    if (inMemoryOwn && inMemoryOwn.checked === true) {
      this.inMemoryOwnSelected();
    }
    if (inMemoryCollection && inMemoryCollection.checked === true) {
      this.inMemoryCollectionSelected();
    }
  }

  giftaidSelected() {
    this.showGiftaid();
    this.hideInMemoryInputs();
    this.hideFromEventInputs();
    this.hideFromOrgInputs();
    this.hideFromLegacyInputs();
    this.clearInMemoryInputs();
    this.clearFromEventInputs();
    this.clearFromOrgInputs();
    this.clearFromLegacyInputs();
  }

  inMemorySelected() {
    this.showInMemoryInputs();
    this.showGiftaid();
    this.hideFromEventInputs();
    this.hideFromOrgInputs();
    this.hideFromLegacyInputs();
    this.clearFromEventInputs();
    this.clearFromOrgInputs();
    this.clearFromLegacyInputs();
  }

  inMemoryOwnSelected() {
    this.showGiftaid();
  }

  inMemoryCollectionSelected() {
    this.hideGiftaid();
  }

  fromEventSelected() {
    this.showFromEventInputs();
    this.hideGiftaid();
    this.hideInMemoryInputs();
    this.hideFromOrgInputs();
    this.hideFromLegacyInputs();
    this.clearInMemoryInputs();
    this.clearFromOrgInputs();
    this.clearFromLegacyInputs();
    this.uncheckGiftaid();
  }

  fromOrgSelected() {
    this.showFromOrgInputs();
    this.hideGiftaid();
    this.hideInMemoryInputs();
    this.hideFromEventInputs();
    this.hideFromLegacyInputs();
    this.clearInMemoryInputs();
    this.clearFromEventInputs();
    this.clearFromLegacyInputs();
    this.uncheckGiftaid();
  }

  fromLegacySelected() {
    this.showFromLegacyInputs();
    this.hideGiftaid();
    this.hideInMemoryInputs();
    this.hideFromEventInputs();
    this.hideFromOrgInputs();
    this.clearInMemoryInputs();
    this.clearFromEventInputs();
    this.clearFromOrgInputs();
    this.uncheckGiftaid();
  }

  hideGiftaid() {
    this.giftaidOption.classList.remove("Giftaid--show");
    this.giftaidOption.classList.add("Giftaid--hide");
  }

  showGiftaid() {
    this.giftaidOption.classList.remove("Giftaid--hide");
    this.giftaidOption.classList.add("Giftaid--show");
  }

  uncheckGiftaid() {
    this.gitaidCheckbox.checked = false;
  }

  hideInMemoryInputs() {
    this.inMemoryInputs.classList.remove("Giftaid--show");
    this.inMemoryInputs.classList.add("Giftaid--hide");
  }

  showInMemoryInputs() {
    this.inMemoryInputs.classList.remove("Giftaid--hide");
    this.inMemoryInputs.classList.add("Giftaid--show");
  }

  clearInMemoryInputs() {
    this.inMemoryInputFields.forEach((input) => {
      input.value = "";
      input.checked = false;
    });
  }

  hideFromEventInputs() {
    this.fromEventInputs.classList.remove("Giftaid--show");
    this.fromEventInputs.classList.add("Giftaid--hide");
  }

  showFromEventInputs() {
    this.fromEventInputs.classList.remove("Giftaid--hide");
    this.fromEventInputs.classList.add("Giftaid--show");
  }

  clearFromEventInputs() {
    this.fromEventInputFields.value = "";
    this.fromEventInputSelect.selectedIndex = 0;
  }

  hideFromOrgInputs() {
    this.fromOrgInputs.classList.remove("Giftaid--show");
    this.fromOrgInputs.classList.add("Giftaid--hide");
  }

  showFromOrgInputs() {
    this.fromOrgInputs.classList.remove("Giftaid--hide");
    this.fromOrgInputs.classList.add("Giftaid--show");
  }

  clearFromOrgInputs() {
    this.fromOrgInputFields.forEach((input) => {
      input.value = "";
      input.checked = false;
    });
  }

  hideFromLegacyInputs() {
    if (this.fromLegacy) {
      this.fromLegacyInputs.classList.remove("Giftaid--show");
      this.fromLegacyInputs.classList.add("Giftaid--hide");
    }
  }

  showFromLegacyInputs() {
    this.fromLegacyInputs.classList.remove("Giftaid--hide");
    this.fromLegacyInputs.classList.add("Giftaid--show");
  }

  clearFromLegacyInputs() {
    this.fromLegacyInputFields.forEach((input) => {
      input.value = "";
    });
  }
}

let hideDonationType = document.querySelector('.hideDonationType');
if (!hideDonationType) {
  document.querySelectorAll(".js-Giftaid").forEach((node) => {
    new GiftaidBlock(node);
  });
}

const donateAmountInput = document.querySelector(".CurrencyInput-input");
const donateAmount = document.querySelector(".js-donation-amount");
const giftAidAmount = document.querySelector(".js-giftaid-amount");

function updateGiftaidContent(e) {
  donateAmount.textContent = `£${(e.target.value * 1).toFixed(2)}`;
  giftAidAmount.textContent = `£${(e.target.value * 0.25).toFixed(2)}`;
}

if (donateAmountInput) {
  donateAmountInput.addEventListener("input", updateGiftaidContent);
}
